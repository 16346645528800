<template>
  <div class="page-content d-flex align-items-center justify-content-center">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-4 col-xl-4 mx-auto">
        <div class="card">
          <div class="auth-form-wrapper px-4 py-5">
            <h4 class="mb-4 justify" align="center">Reset your password</h4>
            <b-alert dismissible :variant="alert_opt.variant" :show="alert_opt.show">{{ alert_opt.message }}</b-alert>
            <form class="forms-sample" @submit.prevent="reset_pwd">
              <div class="row">
                <div class="col-xl-12 col-md-12">
                  <div class="form-group">
                    <label for="input-company-name">New password</label>
                    <input type="password" class="form-control" id="input-new-password" placeholder="Input your new password" name="email" required v-model="v$.form.new_password.$model">
                    <span class="text-danger" v-if="v$.form.new_password.$errors[0]">
                      {{ v$.form.new_password.$errors[0].$message }}
                    </span>
                  </div>
                  <div class="form-group">
                    <label for="input-company-name">Confirm new password</label>
                    <input type="password" class="form-control" id="input-confirm-new-password" placeholder="Retype your new password" name="email" required v-model="v$.form.confirm_password.$model">
                    <span class="text-danger" v-if="v$.form.confirm_password.$errors[0]">
                      {{ v$.form.confirm_password.$errors[0].$message }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-1" align="center">
                <button :disabled="v$.form.$error" type="submit" class="btn btn-primary text-white mr-2 mb-2 mb-md-0">Update password</button>
                <a @click="(e) => { e.preventDefault(); $router.push('/auth/login'); }" href="/auth/login" class="d-block mt-3 text-muted">or Sign in</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {
  required, helpers, minLength, sameAs,
} from '@vuelidate/validators';

import authAPI from '../../../api/auth';
import passwordComplexity from '../../../library/password-complexity';

export default {
  name: 'ResetPassword',
  metaInfo: {
    title: 'Reset Password',
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        new_password: {
          required,
          minLength: minLength(8),
          complexity: helpers.withMessage(() => 'Password should have at least 1 uppercase, 1 lowercase, 1 number and 1 special character', passwordComplexity),
        },
        confirm_password: { required, sameAs: sameAs(this.form.new_password, 'new password') },
      },
    };
  },
  data() {
    return {
      form: {
        new_password: '',
        confirm_password: '',
      },
      alert_opt: {
        variant: 'success',
        show: false,
        message: this.$t('auth.success.reset_password'),
      },
    };
  },
  async mounted() {
    this.v$.form.$touch();
    await this.verify();
  },
  methods: {
    async verify() {
      const response = await authAPI.verify_reset_password({ token: this.$route.params.token });
      if (response.error) {
        this.$router.push('/auth/login');
      }
    },
    async reset_pwd() {
      if (this.v$.form.$error) return;
      const loader = this.$loading.show();
      await authAPI.reset_password({
        token: this.$route.params.token,
        new_password: this.form.new_password,
        confirm_password: this.form.confirm_password,
      }).then(async (res) => {
        this.alert_opt.show = true;
        if (res.error) {
          this.alert_opt.variant = 'warning';
          this.alert_opt.message = this.$t(`auth.error.init_password_${res.error.title}`);
          return;
        }
        setTimeout(() => {
          this.$router.push('/auth/login');
        }, 5 * 1000);
      }).catch(async () => {
        this.alert_opt = {
          show: true,
          variant: 'warning',
          message: this.$t('general.error.general'),
        };
      });
      loader.hide();
    },
  },
};
</script>
